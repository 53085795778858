import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() =>
    import('/src/components/form-components/FieldSet')
)
const FieldSpacer = loadable(() =>
    import('/src/components/form-components/FieldSpacer')
)
const SelectDay = loadable(() =>
    import('/src/components/form-components/SelectDay')
)
const SelectMonth = loadable(() =>
    import('/src/components/form-components/SelectMonth')
)
const SelectYear = loadable(() =>
    import('/src/components/form-components/SelectYear')
)

const StepDob = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet legend={'Date of Birth'} required>
            <FieldSpacer grow={'stretch'}>
                <SelectDay
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <SelectMonth
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <SelectYear
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepDob
